import { ContentBlock, Typography, Button, Header } from '@/components';
import { AppContext } from '@/GlobalProvider/GlobalProvider';
import * as React from 'react';
import { useContext, FC } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';

export const useStyles = createUseStyles({
  link: {
    color: 'blue'
  },
  retryButtonWrap: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  retryButton: {
    marginTop: 20,
    maxWidth: 200
  }
});

export const GeneralError: FC = () => {
  const { id } = useContext(AppContext);
  const styles = useStyles();
  const navigate = useNavigate();

  const returnToHome = () => {
    navigate('/');
  };

  return (
    <>
      <Header />
      <ContentBlock>
        <Typography component="h3">
          Something went wrong... Please contact Size Stream Support{' '}
          <a href="mailto:support@sizestream.com" className={styles.link}>
            support@sizestream.com
          </a>{' '}
          with your company identifier {id}.
        </Typography>
        <div className={styles.retryButtonWrap}>
          <Button
            buttonType="dark"
            text="Return to dashboard"
            onClick={returnToHome}
            className={styles.retryButton}
          />
        </div>
      </ContentBlock>
    </>
  );
};
