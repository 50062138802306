import { getUser, getUserScans } from '@/api';
import { ContentBlock, Typography, Button, Loader, Header } from '@/components';
import {
  formatDateWithTime,
  getFilteredScans,
  getSuccessfulScan,
  useIframeMessageHandler
} from '@/utils';
import { Grid, View } from '@aws-amplify/ui-react';
import * as React from 'react';
import { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStyles } from './styles';
import { AssetsContext } from '@/GlobalProvider/GlobalProvider';
import { ScanStatus } from '@/constants';

const getScanStatus = (status: string) => {
  switch (status) {
    case ScanStatus.Complete:
      return 'Complete';
    case ScanStatus.Failed:
      return 'Failed';
    case ScanStatus.WaitingForData:
      return 'Waiting for Data';
    default:
      return 'Processing';
  }
};

export const Scans = () => {
  const styles = useStyles();
  const navigate = useNavigate();

  const {
    behavior_list: { customer_account_handle }
  } = useContext(AssetsContext);

  const params = useParams();
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [scans, setScans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);

  const getScans = useCallback(async () => {
    setRefreshLoading(true);
    // filter scans to remove canceled
    const filteredScansData = await getFilteredScans(params.customerId);
    setScans(filteredScansData);
    setRefreshLoading(false);
  }, []);

  useIframeMessageHandler(currentCustomer, getSuccessfulScan(scans)?.id);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const customerData = await getUser(params.customerId);
      if (!customerData.status) {
        await getScans();
        setCurrentCustomer(customerData);
      } else {
        navigate('/error');
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    let interval;

    if (!loading) {
      interval = setInterval(() => {
        getScans();
      }, 60000);
    }

    return () => clearInterval(interval);
  }, [loading]);

  if (loading) {
    return <Loader />;
  }

  if (!currentCustomer) return null;

  return (
    <>
      <Header />
      <ContentBlock>
        <div className={styles.header}>
          <Typography component="h2" className={styles.title}>
            Scan History
          </Typography>
          <Typography component="p">
            {customer_account_handle === 'third_party_account_id' ? (
              <>
                <span>Subject ID:</span> {decodeURI(params.accountId)}
              </>
            ) : (
              <>
                <span>Customer:</span> {currentCustomer.firstName}{' '}
                {currentCustomer.lastName}
              </>
            )}
          </Typography>
        </div>
        <div>
          <Button
            isDisabled={refreshLoading}
            text={refreshLoading ? 'Loading' : 'Refresh'}
            buttonType="secondary"
            className={styles.refresh}
            onClick={() => getScans()}
          />
        </div>
        {scans.length > 0 ? (
          <Grid
            columnGap="1rem"
            rowGap="1rem"
            templateColumns="1fr 1fr"
            className={styles.scans}
          >
            {scans.map((scan) => (
              <View key={scan.id} columnSpan={[2, 1]} className={styles.scan}>
                <Typography component="p" className={styles.scanInfo}>
                  <span>Date:</span> {formatDateWithTime(scan.date) || '-'}
                </Typography>
                <Typography component="p" className={styles.scanInfo}>
                  <span>Status:</span> {getScanStatus(scan.status) || '-'}
                </Typography>
                {scan.status === ScanStatus.Complete && (
                  <Button
                    text="Scan Measurements"
                    buttonType="dark"
                    className={styles.scanButton}
                    onClick={() =>
                      navigate(
                        `/scans/${scan.id}/${params.customerId}/${params.accountId}`
                      )
                    }
                  />
                )}
              </View>
            ))}
          </Grid>
        ) : null}
        <Button
          buttonType="secondary"
          text="Back to Profile"
          className={styles.backButton}
          onClick={() =>
            navigate(`/customers/${params.customerId}/${params.accountId}`)
          }
        />
      </ContentBlock>
    </>
  );
};
